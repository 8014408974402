import React, { useState } from 'react'
import { useLocalStorage } from "@uidotdev/usehooks"

function AddItem({ list, setList }) {
  const [showDropdown, setShowDropdown] = useState(false)
  const [warehouse] = useLocalStorage("warehouse", [])
  const [filteredList, setFilteredList] = useState(warehouse)

  const wrapperStyle = {
    display: 'flex',
    justifyContent: 'center',
    margin: '2px',
  }

  const createItem = () => {
    const nameEl = document.querySelector('#addItemNameInput')
    const priceEl = document.querySelector('#addItemPriceInput')
    const name = nameEl.value
    const price = priceEl.value.replace(",",".");
    const badParams = !name || !price || isNaN(price)

    if (badParams) return;

    const newItem = { name, price }

    setList([...list, newItem])

    clearInputs([ nameEl, priceEl ])
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter' || e.keyCode === 13)
      createItem();
  }

  function handleKeyUp(e) {
    const el = document.querySelector('#addItemNameInput')

    if(el.value === "") {
      setFilteredList([])
      setShowDropdown(false)
      return 
    }
    
    const warehouseFiltered = warehouse.filter(item => {
      const search = el.value
      return item.name.includes(search) ? item : undefined 
    })

    setShowDropdown(true)
    setFilteredList(warehouseFiltered)
  }

  function clearInputs(inputs) {
    inputs.forEach(input => input.value = "")
  }

  let dropDownClassName = 'column is-three-fifths dropdown'
  if (showDropdown)
    dropDownClassName += ' is-active'

  function printWarehouseItem(item) {
    return (
      <button 
        className="dropdown-item" 
        key={item.name} 
        onClick={() => {
          document.querySelector('#addItemNameInput').value = item.name
          setShowDropdown(false)
        }
      }>
        {item.name}
      </button>
    )
  }

  return (
    <div className="columns" style={wrapperStyle}>
      <div className={dropDownClassName}>
        <div className="dropdown-trigger">
          <input 
            id="addItemNameInput"
            className="input is-warning"
            type="text"
            placeholder="Item"
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
          />
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            {filteredList.map(printWarehouseItem)}
          </div>
        </div>
      </div>
      <div className="column">
        <input 
          id="addItemPriceInput"
          className="input is-warning"
          type="text"
          placeholder="€"
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="column has-text-left">
        <button className="button" onClick={ createItem }>
          +
        </button>
      </div>
    </div>
  )
}

export default AddItem
